<template>
    <div>
        <div>
            <h4 class="mb-4">Profile Settings</h4>
            <p>Hello, {{ currentUser.name }}!</p>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            currentUser() {
                return this.$store.getters['auth/currentUser'];
            }
        }
    }
</script>
